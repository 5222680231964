import { cn } from '../../lib/classname.ts';

type DividerProps = {
  className?: string;
};

export function Divider(props: DividerProps) {
  const { className } = props;

  return (
    <div
      className={cn(
        'flex w-full items-center gap-2 py-6 text-xs text-slate-600',
        className,
      )}
    >
      <div className="h-px w-full bg-slate-200"></div>
      OR
      <div className="h-px w-full bg-slate-200"></div>
    </div>
  );
}
