import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from '../Interface/Dialog.tsx';
import { GitHubButton } from './GitHubButton.tsx';
import { GoogleButton } from './GoogleButton.tsx';
import { Divider } from './Divider.tsx';
import { EmailLoginForm } from './EmailLoginForm.tsx';
import { useStore } from '@nanostores/react';
import { hasLoginDialog } from '../../stores/page.ts';
import { useEffect } from 'react';
import { AUTH_REDIRECT_KEY } from '../../lib/auth-redirect.ts';

export function LoginDialog() {
  const $hasLoginDialog = useStore(hasLoginDialog);

  // Set the page location to the current page
  // in the localstorage so that we can redirect
  // the user back to the page after login.
  useEffect(() => {
    if ($hasLoginDialog) {
      localStorage.setItem(AUTH_REDIRECT_KEY, window.location.pathname);
    }
  }, [$hasLoginDialog]);

  return (
    <Dialog open={$hasLoginDialog} onOpenChange={hasLoginDialog.set}>
      <DialogContent
        className="sm:max-w-[425px]"
        onOpenAutoFocus={(e) => e.preventDefault()}
      >
        <DialogHeader className="text-center">
          <DialogTitle>Login to your account</DialogTitle>
          <DialogDescription>
            You must be logged in to perform this action.
          </DialogDescription>
        </DialogHeader>
        <div className="grid gap-2 pt-2">
          <GitHubButton />
          <GoogleButton />

          <Divider className="py-3" />

          <EmailLoginForm />

          <div className="mt-3 text-center text-sm text-slate-600">
            Don't have an account?{' '}
            <a
              href="/signup"
              className="font-semibold text-gray-900 underline underline-offset-2 hover:underline"
            >
              Sign up
            </a>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
